@import "../styles/variables";

.dashboards-menu {
  display: flex;
  justify-content: center;
  max-width: 1315px;
  margin: 10px auto 10px;

  .MuiButtonBase-root {
    color: $black;
    background-color: transparent;
    border-radius: 0;
    margin: 0 10px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
    text-transform: none;
  }
  .feedback-button {
    background-color: $primary;
    // border: 2px solid $primary;
    letter-spacing: 0;
    text-transform: none;
  }
  .MuiButton-containedPrimary.feedback-button {
    .MuiButton-label {
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  .ppt-button {
    background-color: transparent;
    font-weight: 300;
    letter-spacing: 0;
    text-transform: none;
    min-width: 140px;
  }

  .MuiButton-containedPrimary.ppt-button {
    color: $black;
    .MuiButton-label {
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  .MuiButton-label {
    padding-bottom: 4px;
  }

  .MuiButton-containedPrimary {
    color: #454545;
    font-weight: 500;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background: none;
    }

    .MuiButton-label {
      border-bottom: 4px solid $primary;
      margin-bottom: 0;
      padding-bottom: 4px;
    }
  }
  .button-icon {
    font-size: 14px;
    margin-left: 10px;
  }
}

.loading-wrp {
  padding-top: 150px;
}

.header-panel {
  background: $main-dark;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 3px;
  padding-bottom: 0px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom: 2px solid #c5c5c5;

  .MuiCircularProgress-root {
    display: none;
  }

  .MuiCard-root {
    color: #454545;
    background: $main-dark;
  }

  .MuiTableCell-body {
    color: #454545;
  }

  .MuiCardContent-root:last-child {
    margin: 0;
    padding: 0 20px 0 0;
  }

  .MuiPaper-root {
    box-shadow: none;
  }

  .MuiGrid-container {
    margin: 0 auto;
  }

  .MuiGrid-item {
    padding: 0 !important;
  }

  .paged-table .table-05 table tbody tr td {
    color: #454545;
    opacity: 0.8;
  }
}

.main-content {
  margin: 0 auto;
  padding: 0 5px;

  .rtco-input-component{
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid rgb(254, 80, 0);
  }

  > .MuiGrid-root {
    max-width: 100%;
  }
}

.app-errors {
  padding-top: 100px;
}
