html {
  // background: url("/images/bkg.png") repeat, rgba(0, 0, 0, 0.03) !important;
}

body {
  margin: 0;
  font-family: "Proxima Nova", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f8fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  padding: 0;
}
