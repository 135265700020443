@import "../styles/variables";

.visualization {
  .MuiCardContent-root {
    padding: 0;

    &:last-child {
      padding: 0;
    }
  }

  .MuiCircularProgress-root {
    opacity: 0.6;
  }

  .MuiCard-root {
    height: 100%;
    box-shadow: none;
    border-radius: 3px !important;
    border: 1px solid #ddd;
  }

  .divider {
    background: none;
    box-shadow: none;
    border: none !important;
    margin: 30px 0 20px;

    .visualization-container h6 {
      padding-left: 5px !important;
      color: #454545 !important;
      border-bottom: 2px solid #7da2bd;
      padding-bottom: 15px;
      text-transform: uppercase;
      opacity: 0.8;
    }
  }

  .visualization-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .name {
    line-height: 20px;
    padding: 20px 30px 10px;
    border-bottom: none;
    font-weight: 500;
    font-size: 20px;
    font-weight: 500;
    color: #222220;
    text-align: left;

    &.text {
      padding-top: 5px;
      padding-bottom: 5px;
      border: none;
    }
  }

  .legend {
    text-align: left;
    opacity: 0.7;
    font-size: 12px;
    padding: 5px 20px 10px;
  }

  .switch-account {
    display: inline-block !important;
    text-align: center;
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .honeycomb-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin: 40px 50px 50px 30px;
    line-height: 19px;    
  }

  .honeycomb-legend {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 10px;
    padding-right: 10px;
  }
}

.honeycomb-row-even {
  padding-left: 10px;
}

.honeycomb-icon {
  font-size: 30px;
  vertical-align: sub;
}
