.html-01 {
  padding: 20px;
  p,
  h4,
  li {
    text-align: left;
  }
  h4 {
    font-size: 16px;
  }
}
