@import "../../styles/variables";

.value-link {
  color: $primary;
  text-decoration: none;
}

.green {
  color: green;
  padding-right: 0;
}
.column-icon {
  padding-left: 20px;
}

.honeycomb-column {
  font-size: 26px;
  padding-left: 30%;
}
