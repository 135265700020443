.line-chart {
  min-height: 300px;
  .recharts-legend-wrapper {
    padding-bottom: 10px;
  }
  .recharts-wrapper svg {
    overflow: inherit !important;
  }

  .MuiCircularProgress-root {
    margin: 30px auto;
  }
}
