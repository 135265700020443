.custom-formatter {
  .info{
    color: #f28e2b;
  }

  .warning{
    color: #fe5000;
  }
  
  .error {
    color: #e15759;
  }

  .success {
    color: #59a14f;
  }

  .MuiSvgIcon-root {
    vertical-align: middle;
    padding-right: 8px;
    font-size: 30px;
  }

  .font-weight-bold {
    font-weight: 700;
  }
}
