@import "./styles/variables";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background: $main-dark;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selected-accounts {
  cursor: pointer;
  font-size: 12px;
  text-align: left;
  padding-left: 2px;
}

.hierarchy-selection {
  height: 15px;
  font-size: 14px;
  text-align: left;
  padding-left: 2px;
}

.right-buttons {
  text-align: right;
  padding-right: 12px;
}

.right-buttons,
.download-component {
  .MuiButtonBase-root {
    font-size: 12px !important;
    border-radius: 0 !important;
    border: 1px solid #c5c5c5 !important;
    background-color: #fff !important;
    color: #454545 !important;
    margin: 0 !important;

    &:hover {
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    }

    .MuiButton-label {
      padding-bottom: 0 !important;
    }
  }
}
