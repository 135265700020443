.array-environment-select {
  font-size: 12px;
  color: #454545;
  display: flex;
  align-items: center;
  padding-left: 2px;

  .MuiSelect-root {
    font-size: 12px;
    color: #454545;
    margin-left: 10px;
  }
  .MuiSelect-icon {
    color: #454545;
  }
}
