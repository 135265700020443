.download-component {
  position: absolute;
  top: 15px;
  right: 25px;
  text-align: right;
  padding: 5px;
  .CSVLink {
    display: none;
  }
  .download-button {
    max-height: 30px;
    min-width: 130px;
  }
}
