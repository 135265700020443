@import "../../styles/variables";

.feedback-form {
  .MuiBackdrop-root {
    background-color: rgba(25, 23, 40, 0.5);
  }
  .MuiDialogContent-dividers {
    border: none;
  }

  .select-quarter {
    width: 300px;
    margin-bottom: 20px;
  }

  .select-file {
    width: 200px;
    height: 100%;

    .MuiInputBase-root {
      height: 100%;
    }

    input {
      height: 100%;

      &:disabled {
        color: $black;
      }
    }
  }

  .select-file-label {
    display: flex;
    height: 40px;
  }

  .select-file-button {
    height: 100%;
    width: 100px;
    background-color: $black;
    color: white;
    text-transform: none;
    pointer-events: none;

    &:hover {
      color: $black;
    }
    &:disabled {
      color: grey;
    }
  }

  .dialog-actions {
    display: flex;
    justify-content: flex-end;
  }

  .buttons-container {
    display: flex;
    flex: 1;
  }

  .select-loading {
    display: flex;
    height: 100%;
  }

  .cancel-button {
    margin-right: 15px;
  }

  .delete-button {
    margin-left: 16px;
    text-transform: none;
    color: $dangerousAction;
  }

  .delete-icon {
    color: $dangerousAction;
    margin-right: 10px;
  }

  .button-progress-container {
    display: flex;
    align-items: center;
  }

  .MuiPaper-rounded {
    border-radius: 10px;
  }

  .MuiDialogTitle-root {
    color: #222220;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    padding-top: 18.5px;
  }

  .MuiFormHelperText-root {
    color: #8ca5c0;
  }

  .MuiOutlinedInput-root,
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid #e0e7ff;
  }

  .MuiOutlinedInput-notchedOutline {
    background-color: rgba(224, 231, 255, 0.2);
  }

  .MuiInputLabel-root {
    color: #8ca5c0;
    font-size: 16px;
    font-weight: 500;
    line-height: 12px;
    margin-bottom: 10px;
  }

  .MuiDialogActions-root {
    padding: 0;
    margin: 25px 0 5px;
  }
}

.accountSelection {
  color: #fe5000;
}
