@import "../styles/variables";

.additional-slides {
  .scroll-right,
  .scroll-left {
    background-color: $primary;
    position: absolute;
    top: 50%;
    opacity: 0.5;
    z-index: 2;
    padding-inline: 4px;
    color: white;

    &:hover {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
      background-color: $primary;
      opacity: 0.9;
    }
  }

  .scroll-left {
    left: 1rem;
  }

  .scroll-right {
    right: 1rem;
  }

  .slide {
    border: 2px solid transparent;

    &--selected {
      border: 2px solid $primary;
    }

    &:hover {
      background: #efefef;
    }
  }
}
