@import "../styles/variables";

.feedback-page {
  margin-left: 5px;
  margin-right: 5px;
  .MuiCardContent-root {
    padding: 0px;
    padding-bottom: 0px !important;
  }
  .MuiTablePagination-root {
    .MuiIconButton-label {
      border: 1px;
      border-color: #8ca5c0;
      border-style: solid;
      border-radius: 2px;
      padding: 3px;
    }
    .MuiTablePagination-input {
      border: 1px;
      border-color: #8ca5c0;
      border-style: solid;
      border-radius: 2px;
    }
  }

  .download-icon {
    font-size: 14px;
    margin-right: 5px;
  }

  .MuiTable-root {
    tr {
      td {
        font-size: 13px !important;;
      }
      td:nth-child(3),td:nth-child(4)  {
        width: 50px !important;
      }
      td:nth-child(6) {
        width: 250px !important;
      }
      td:nth-child(8) {
        width: 150px !important;
      }
      td:nth-child(9) {
        width: 350px !important;
      }
    }
  }
  .pvr-download-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
    text-transform: none;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      background-color: transparent;
    }
    &:focus {
      text-decoration: none;
    }
  }
  .detail-panel {
    padding: 10px 25px;

    p {
      white-space: pre-line;
      max-width: 80vw;
    }
  }
  .circular-loading {
    padding-left: 25px;
  }

  .filter {
    font-weight: bold;
    cursor: pointer;
  }

  .filter-count {
    background-color: $primary;
    border-radius: 50%;
    width: 24px;
    line-height: 24px;
    height: 24px;
    color: #fff;
    text-align: center;
    display: inline-block;
  }

  .clear-all {
    margin-right: 20px;
  }
}
