.non-dashboard {
  padding-top: 15px;
  font-size: 34px;
  text-align: left;
  color: #454545;
  display: flex;
}

.contact-btn {
  font-size: 12px;
  font-weight: 500;
  color: #454545;
  transform: translate(0px, -25px);
}
