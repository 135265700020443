@font-face {
  font-family: "Proxima Nova";
  font-weight: normal;
  font-style: normal;
  src: url("/mf/pvr/fonts/proxima_nova-webfont.woff2") format("woff2"),
    url("/mf/pvr/fonts/proxima_nova-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: 500;
  font-style: normal;
  src: url("/mf/pvr/fonts/proxima_nova_semibold-webfont.woff2") format("woff2"),
    url("/mf/pvr/fonts/proxima_nova_semibold-webfont.woff") format("woff");
}

@font-face {
  font-family: "Proxima Nova";
  font-weight: bold;
  font-style: normal;
  src: url("/mf/pvr/fonts/proxima_nova_bold-webfont.woff2") format("woff2"),
    url("/mf/pvr/fonts/proxima_nova_bold-webfont.woff") format("woff");
}
