@import "../../styles/variables";

.paged-table {
  &.lazy {
    min-height: 312px;
  }
  flex-grow: 1;
  min-height: 100px;
  height: 100%;
  .MuiTablePagination-root {
    .MuiIconButton-label {
      border: 1px;
      border-color: #8ca5c0;
      border-style: solid;
      border-radius: 2px;
      padding: 3px;
    }
    .MuiTablePagination-input {
      border: 1px;
      border-color: #8ca5c0;
      border-style: solid;
      border-radius: 2px;
    }
  }
  .MuiTableCell-body {
    div.custom-cell {
      &.compact {
        max-width: 400px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  .fleet-legend {
    padding-left: 20px;
    .MuiSvgIcon-root {
      vertical-align: middle;
      padding-right: 10px;
      font-size: 30px;
      &.dot-icon {
        padding-bottom: 5px;
        &.red {
          color: red;
        }
        &.green {
          color: green;
        }
        &.error {
          color: #e15759;
        }
        &.info {
          color: #f28e2b; 
        }
      }
    }
  }
  .hexagon-legend-icon {
    padding-top: 0px !important;
  }
  .detailPanel {
    padding: 0 25px;
    p {
      white-space: pre-line;
      max-width: 80vw;
    }
  }

  .MuiCircularProgress-root {
    margin: 20px 0;
  }

  .MuiTableContainer-root {
    flex-grow: 1;
    height: 100%;
  }

  .pagination {
    flex-grow: 1;
    padding-bottom: 10px !important;
    overflow: hidden !important;

    .MuiTablePagination-actions {
      button {
        padding: 5px 12px;
      }
    }
  }

  table {
    thead {
      tr {
        th {
          white-space: nowrap;
          vertical-align: bottom;
          font-weight: bold;

          &.border {
            border-bottom: none;
          }
        }
      }
    }
  }

  .table-01 {
    table,
    thead,
    tbody,
    tr,
    td,
    th {
      display: flex;
      text-align: center;
      font-weight: 500;
      padding: 0;
      color: #7da2bd;
      font-size: 18px;
    }

    thead,
    tbody,
    td,
    tr,
    th {
      flex: 1;
    }

    table {
      height: 100%;
      flex-direction: column;
      padding-top: 10px;

      thead {
        align-items: flex-end;

        tr {
          flex-direction: row;
          display: flex;
        }

        th {
          justify-content: center;
          align-items: flex-end;
          background: transparent;
        }

        tr {
          th,
          .MuiTableSortLabel-root {
            text-align: center;
            font-weight: 500;
            padding: 0;
          }
        }
      }

      tbody {
        justify-content: flex-start;
        align-items: flex-start;

        tr {
          flex-direction: row;
          td {
            justify-content: center;
            border-bottom: none;
            color: $primary;
            font-size: 30px;
            font-weight: bold;
            padding: 5px 0;
          }
        }
      }
    }
  }

  .table-02 {
    table {
      thead th {
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }

      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: #f5f7ff;
          }

          td {
            border-top: 2px solid #f5f7ff;
            border-bottom: none;
            font-size: 14px;
            font-weight: 400;
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-child {
              padding-left: 30px;
            }

            &:last-child {
              padding-right: 30px;
            }

            &:nth-child(2) {
              text-align: right;
              color: #222220;
            }
          }
        }
      }
    }
  }

  .table-03 {
    /* &:not(.paged-table.lazy) {
      max-height: 70vh;
    } */

    table {
      font-size: 13px;
      thead th {
        background: white;
        white-space: nowrap;

        font-size: 14px;
        padding-bottom: 8px;
        color: #93b9d9;
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }
      tbody {
        tr {
          &:last-child {
            td {
              border-bottom: none;
            }
          }

          &:nth-of-type(even) {
            background-color: #f5f7ff;
          }

          td {
            &:first-child {
              padding-left: 30px;
            }

            &:last-child {
              padding-right: 30px;
            }
          }
        }
      }
    }
  }

  .table-04.fleet-detail {
    table {
      font-size: 14px;
      thead {
        tr {
          th {
            background: white;
            white-space: nowrap;
            font-size: 14px;
            padding-bottom: 5px;
            color: #93b9d9;
          }
        }
      }

      tbody {
        tr {
          
          &:nth-of-type(odd) {
            background-color: #f9fbff;
          }

          td {
            border-top: 2px solid #f5f7ff;
            font-size: 12px;
            font-weight: 400;
            .green {
              color: green;
            }
          }

          td:nth-child(1) {
            white-space: nowrap;
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .table-04.fleet-detail-executive {
    table {
      font-size: 14px;
      thead {
        tr {
          th {
            background: white;
            white-space: nowrap;
            font-size: 14px;
            padding-bottom: 5px;
            color: #93b9d9;
          }
        }
      }

      tbody {
        tr {
          &:first-child {
            display: none !important;
          }
          &:nth-of-type(odd) {
            background-color: #f9fbff;
          }

          td {
            border-top: 2px solid #f5f7ff;
            font-size: 12px;
            font-weight: 400;
            .green {
              color: green;
            }
          }

          td:nth-child(1) {
            white-space: nowrap;
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .table-04.volume-search {
    div{
      div{
        div{
          div{
            overflow-y: hidden !important;
            max-height: max-content !important;
          }
        }
      }
    }
    table {
      font-size: 14px;
      thead {
        tr {
          th {
            background: white;
            white-space: nowrap;
            font-size: 14px;
            padding-bottom: 5px;
            color: #93b9d9;
          }
        }
      }

      tbody {
        tr {
          &:first-child {
            display: none !important;
            // background-color: red;
          }
          &:nth-of-type(odd) {
            background-color: #f9fbff;
          }

          td {
            border-top: 2px solid #f5f7ff;
            font-size: 12px;
            font-weight: 400;
            .green {
              color: green;
            }
          }

          td:nth-child(1) {
            white-space: nowrap;
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .table-04 {
    table {
      font-size: 14px;
      thead {
        tr {
          th {
            background: white;
            white-space: nowrap;
            font-size: 14px;
            padding-bottom: 5px;
            color: #93b9d9;
          }
        }
      }

      tbody {
        tr {
          &:nth-of-type(odd) {
            background-color: #f9fbff;
          }

          td {
            border-top: 2px solid #f5f7ff;
            font-size: 12px;
            font-weight: 400;
            .green {
              color: green;
            }
          }

          td:nth-child(1) {
            white-space: nowrap;
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .table-05 {
    table {
      display: flex;
      justify-content: flex-end;

      tbody {
        display: flex;
        flex-direction: column;

        tr {
          display: flex;
          justify-content: flex-end;
          margin: 2.5px 0;

          td:first-child {
            text-align: right;
            padding-right: 5px;
          }
          td {
            padding: 0;
            display: flex;
            border: none;
            color: #95bada;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .table-06 {
    table,
    thead,
    tbody,
    tr,
    td,
    th {
      display: flex;
      text-align: center;
      font-weight: 500;
      padding: 0;
      color: #7da2bd;
      font-size: 18px;
    }

    thead,
    tbody,
    td,
    tr,
    th {
      flex: 1;
    }

    table {
      height: 100%;
      flex-direction: column;
      padding-top: 10px;

      thead {
        align-items: flex-end;

        tr {
          flex-direction: row;
          display: flex;
        }

        th {
          justify-content: center;
          align-items: flex-end;
          background: transparent;
        }

        tr {
          th,
          .MuiTableSortLabel-root {
            text-align: center;
            font-weight: 500;
            padding: 0;
          }
        }
      }

      tbody {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;

        tr {
          flex-direction: row;
          min-width: 100%;
          td {
            justify-content: center;
            border-bottom: none;
            color: $primary;
            font-size: 30px;
            font-weight: bold;
            padding: 5px 0;
          }
        }
      }
    }
  }

  .array-upgrade-detail {
    table {
      tbody {
        td:nth-child(2) {
          white-space: nowrap;
        }
      }
    }
  }

  .fleet-detail {
    table {
      tbody {
        td:nth-child(2) {
          white-space: nowrap;
        }
      }
    }
  }

  .survey-comments {
    table {
      thead {
        tr {
          th:nth-child(1) {
            width: 100px;
          }

          th:nth-child(2) {
            width: 400px !important;
          }

          th:nth-child(3) {
            width: 100% !important;
          }
        }
      }
    }
  }
}
