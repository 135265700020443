.hostname-select {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
  .select-input {
    width: 150px;
  }
  .clear-button {
    // float: right;
    padding-right: 10px;
    color: #8ca5c0;
    font-size: 14px;
  }
  .popover-button {
    font-size: 15px;
    font-weight: 500;
    .MuiSvgIcon-root {
      vertical-align: bottom;
      margin-bottom: 2px;
    }
  }
  div.MuiPaper-root {
    transition: none !important;
  }
}
.hostname-select-popover {
  width: 220px;
  margin-top: 20px;
  .MuiList-padding {
    width: 100%;
    margin-top: 5px;
    padding-left: 0;
    overflow-y: scroll;
    max-height: 300px;
  }

  .MuiOutlinedInput-root {
    border: 1px solid #85a6c3;
    margin-left: 10px;
    margin-right: 10px;
    input::placeholder {
      color: #85a6c3;
    }
    .MuiSvgIcon-root {
      color: #85a6c3;
    }
  }
  .MuiListItem-root {
    padding-top: 0px;
    padding-bottom: 0px;
    .MuiTypography-root {
      font-size: 14px;
    }
  }
}
