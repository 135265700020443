.array-genealogy {
  min-height: 100px;
  padding: 10px;
  .empty-data {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .MuiTableContainer-root {
    max-height: 90vh;
    overflow-x: hidden;
  }
  .MuiTableCell-head {
    font-size: 15px;
    min-width: 110px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .circular-progress {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .custom-scrollbar {
    font-size: 1px;
  }
  table {
    thead {
      tr {
        th {
          color: #9aa1a9;
          background-color: #fff;
          vertical-align: top;
          text-align: center;
          z-index: 3;
        }
        th:first-child {
          z-index: 5;
          top: 0;
          left: 0;
          position: sticky;
          font-weight: 500 !important;
        }
      }
    }
    tbody {
      td {
        white-space: nowrap;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        border-left: 1px solid rgba(224, 224, 224, 1);

        &:first-child {
          color: #9aa1a9;
          background-color: #fff;
          font-size: 15px;
          /* Stick to the left */
          left: 0;
          position: sticky;
          vertical-align: center;
          z-index: 2;
          border-bottom: 0px;
          border-left: 0px;
          font-weight: 500;
        }
      }
    }
  }
  .model {
    font-size: 14.5px;
    color: #9aa1a9;
    text-align: center;
    .capacity {
      font-size: 13px;
    }
    .image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 50px;
      height: 30px;
      margin: 0 auto;
    }
  }

  .FA-4 {
    .image {
      background-image: url("/mf/pvr/images/arrays/FA400.png");
    }
  }

  .X-SERIES {
    .image {
      background-image: url("/mf/pvr/images/arrays/Xseries.png");
    }
  }

  .X-90 {
    .image {
      background-image: url("/mf/pvr/images/arrays/X90.png");
    }
  }

  .C6 {
    .image {
      background-image: url("/mf/pvr/images/arrays/C60.png");
    }
  }

  .M-SERIES {
    .image {
      background-image: url("/mf/pvr/images/arrays/Mseries.png");
    }
  }

  .FS {
    .image {
      background-image: url("/mf/pvr/images/arrays/FS.png");
    }
    .capacity {
      display: none;
    }
  }

  .FA {
    .image {
      background-image: url("/mf/pvr/images/arrays/FA.png");
    }
    .capacity {
      display: none;
    }
  }
}
