.accountInline {
  marginright: 5px;
}

.bullet {
  vertical-align: 0;
  margin: 0 10px;
  width: 8px !important;
  height: 8px !important;
}
