.value-type {
  padding-top: 5px;
  padding-bottom: 5px;
}

.value-01 {
  font-size: 34px;
  padding-top: 0;
  padding-bottom: 0;
  .value-content {
    display: flex;
    justify-content: flex-start;
    label, div, span {
      font-size: 16px;
      font-weight: normal;
    }
    &.loading {
      opacity: 0.4;
    }
  }
}
