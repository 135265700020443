.feature-list {
  &.customer-adoption {
    table {
      tbody {
        tr {
          td {
            font-size: 15px;
            width: 10%;
          }
        }
      }
    }
    .main-label {
      width: 20%;
      h4 {
        margin: 0;
      }
    }
  }

  .hidden {
    th {
      visibility: hidden;
      height: 0;
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 0;
      line-height: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  min-height: 200px;
  .MuiCircularProgress-root {
    margin-top: 80px;
  }

  .main-label {
    font-size: 13px;
    width: 20%;

    h4 {
      color: #93b9d9;
      font-size: 14px;
    }
    .MuiSvgIcon-root {
      font-size: 16px;
      vertical-align: text-bottom;
    }
  }
  .feature {
    font-size: 12px;

    .MuiSvgIcon-root {
      font-size: 16px;
      vertical-align: text-bottom;
      margin-right: 5px;
    }
  }
  .feature-adopted {
    font-weight: bold;
    font-size: 16px;
  }
  .green {
    color: #59a14f;
    font-weight: 500;
  }
  .black {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }
  .black_hyperlink {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
    text-decoration: underline;
  }
  .green_hyperlink {
    color: #59a14f;
    font-weight: 500;
    text-decoration: underline;
  }
  .orange {
    color: #f28e2b;
    font-weight: 500;
  }
  .orange_hyperlink {
    color: #f28e2b;
    font-weight: 500;
    text-decoration: underline;
  }
  table {
    table-layout: fixed ;
    thead {
      tr {
        th {
          text-align: center;
          color: #93b9d9;
          font-size: 15px;
          font-weight: bold;
          white-space: nowrap;
        }
        th:first-child {
          text-align: left;
          color: #000000;
          padding-left: 10px;
          font-size: 17px;
          font-weight: normal;
          width: 25%;
        }
      }
    }
    tbody {
      tr {
        td {
          font-size: 15px;
          width: 10%;
          text-align: center;
          border-bottom: none;
          padding: 10px;
        }
        td:first-child {
          text-align: left;
        }
      }
    }
  }
}
