.account-selector {
  .disabled{
    color: grey;
  }

  .no-partner-legend {
    width: 20px;
    height: 20px;
    background-color: grey;
  }

  .partner-legend {
    width: 20px;
    height: 20px;
    background-color: black;
  }

  .mr-5 {
    margin-right: 5px;
  }
  
  .mt-10{
    margin-top: 10px;
  }

  .MuiDialog-paperWidthSm {
    max-width: 750px;
  }
  .MuiDialog-paper {
    height: 600px;
  }

  .select-all {
    position: absolute;
    top: 28px;
    left: 20px;
    z-index: 900;
    cursor: pointer;
    text-align: center;
    width: 110px;
  }

  .parent-selection {
    font-size: 12px;
    margin-left: 15px;
    &:hover {
      cursor: pointer;
    }
  }

  .search-btn {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .table-wrp {
    position: relative;
  }

  .overlay {
    z-index: 100;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
  }
  .account-hierarchy {
    display: flex;
    align-content: flex-start;
    .MuiButtonBase-root {
      color: black;
      background-color: transparent;
      border-radius: 0;
      padding: 4px 10px;
      margin: 0 10px;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 19px;
      text-transform: none;
    }
    .MuiButton-containedPrimary {
      color: #fe5000;
      .MuiButton-label {
        border-bottom: 2px solid #fe5000;
      }
    }
  }
  table {
    thead tr th {
      color: #8ca5c0;
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #fe5000;
      }
    }
    tbody {
      tr:last-child {
        td {
          border-bottom: none;
        }
      }
      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #fe5000;
      }
    }
  }
}
